.label {
  display: inline-block;
  font-weight: 600;
  margin-bottom: 5px;
}
.inputArea,
.displayArea {
  border: 1px solid var(--grey-border);
  border-radius: 8px;
  padding: 12px 32px;
  width: 100%;
  background-color: var(--white);
}
.inputArea {
  resize: none;
  overflow-y: hidden;

  &:focus {
    outline: none;
    border: 1px solid var(--black-text);
  }
  &::placeholder {
    color: var(--grey-text);
  }
}
.displayArea {
  ul {
    padding-inline-start: 24px;
    margin: 0;
  }
  p {
    margin: 0;
  }
}
