.container {
  margin-top: 20px;
}
.filtersContainer {
  margin-bottom: 20px;
}
.searchWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.searchInput {
  position: relative;
  input {
    width: 520px;
    padding: 10px 104px 10px 42px;
    border-radius: 8px;
    border: 1px solid var(--grey-border-4);
    &:focus {
      outline: none;
      border: 1px solid var(--black-text);
    }
    &::placeholder {
      color: var(--grey-text);
    }
  }
  .searchIcon {
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
.numberRecord {
  font-weight: 600;
  margin-left: 16px;
  color: var(--grey-text);
}
.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.selectFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  :global {
    .selectButton {
      &:nth-child(1) {
        width: 158px;
        .selectDropdown {
          width: 228px;
        }
      }
      &:nth-child(2) {
        width: 362px;
        .selectButtonWrapper {
          color: var(--blue-accent);
        }
      }
    }
  }
}
.buttonWrapper {
  display: flex;
  gap: 10px;
  display: flex;
}

.buttonCell {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}
