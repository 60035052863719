.container {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  input[type='radio'] {
    appearance: none;
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0;

    &:checked + .radio .dot {
      opacity: 1;
    }
  }
}

.radio {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--blue-accent);
  border-radius: 7px;
  width: 14px;
  height: 14px;
  margin: 2px;
}

.dot {
  position: absolute;
  top: 2px;
  left: 2px;
  opacity: 0;
  transition: all ease 0.2s;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: var(--blue-accent);
}

.labelText {
  font-weight: bold;
  margin-left: 8px;
}
