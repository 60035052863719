@import '../../../assets/style/effects.scss';

.header {
  padding: 20px 0;
  gap: 15px;
}
.container {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.inputItem {
  // Centre
  &:nth-child(1) {
    :global {
      .selectButton {
        width: 372px;
      }
    }
  }
  // Role
  &:nth-child(2) {
    display: flex;
    gap: 20px;
    .viewJob {
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
      color: var(--blue-accent);
      margin-top: 35px;
    }
    :global {
      .selectButton {
        width: 228px;
      }
    }
  }
  // Pax
  &:nth-child(3) {
    input {
      width: 118px;
    }
  }
  // Schedule
  &:nth-child(4) {
    width: 372px;
  }
  // Additional Info
  &:nth-child(6) {
    width: 372px;
    textarea {
      height: 96px;
    }
  }
}

.buttonWrapper {
  display: flex;
  gap: 36px;
}

.jobModalTitle {
  display: flex;
  gap: 8px;
  align-items: center;
}

.jobModalLabel {
  font-weight: 600;
  margin-top: 15px;
}

.jobModalContent {
  ul {
    padding-inline-start: 24px;
    margin: 0;
  }
  p {
    margin: 0;
  }
}

.header,
.headerRow,
.moreButton,
.moreToggle {
  display: flex;
  align-items: center;
}

.headerRow {
  &:first-child {
    margin-bottom: 8px;
  }
}

.moreToggle {
  margin-left: 16px;
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background-color: var(--light-blue);
  position: relative;
  justify-content: center;
}

.moreButton {
  cursor: pointer;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  border-radius: 10px;
  padding: 16px;
  background-color: var(--white);
  width: max-content;
  gap: 8px;
  @include box-shadow-1;
}

.jobInfoText {
  padding: 0 9px;
  color: var(--grey-text);
  &:first-of-type {
    border-right: 1px solid var(--grey-border-3);
  }
}
