.container {
  margin-top: 20px;
}
.filtersContainer {
  margin-bottom: 20px;
}
.searchWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.searchInput {
  position: relative;
  input {
    width: 520px;
    padding: 10px 148px 10px 42px;
    border-radius: 8px;
    border: 1px solid var(--grey-border-4);
    &:focus {
      outline: none;
      border: 1px solid var(--black-text);
    }
    &::placeholder {
      color: var(--grey-text);
    }
  }
  .searchIcon {
    position: absolute;
    top: 8px;
    left: 8px;
  }
  .searchFilterWrapper {
    position: absolute;
    top: 7px;
    right: 12px;
    :global {
      .selectButton {
        width: 128px;
      }
      .selectButtonWrapper {
        background-color: var(--grey-border-3);
        padding: 0 8px;
        height: 27px;
      }
      .selectDropdown {
        white-space: nowrap;
      }
    }
  }
}
.numberRecord {
  font-weight: 600;
  margin-left: 16px;
  color: var(--grey-text);
}
.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.selectFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  :global {
    .selectButton {
      // Role
      &:nth-child(2) {
        width: 216px;
      }
      // District
      &:nth-child(3) {
        width: 158px;
        .selectDropdown {
          white-space: nowrap;
        }
      }
      // Status
      &:nth-child(4) {
        width: 260px;
        .selectDropdown {
          width: 260px;
        }
      }
      // Payroll month
      &:nth-child(5) {
        width: 170px;
        .selectDropdown {
          width: 170px;
        }
      }
    }
  }
}
.buttonWrapper {
  display: flex;
}

.flexCell {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}

.flexRight {
  div:first-child {
    margin-bottom: 4px;
  }
}

.avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-position: center;
  background-size: cover;
  background-color: var(--grey-border-4);
}

.greyText {
  color: var(--grey-text);
}

.redText {
  color: var(--dark-red);
}

.buttonCell {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.nowrap {
  white-space: nowrap;
}

.customFilter {
  height: 40px;
  background-color: var(--white);
  border-radius: 4px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.removeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0 6px 6px;
  cursor: pointer;
  svg {
    width: 12px;
    height: 12px;
  }
}
