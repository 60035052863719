.formContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.loginControl {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resend {
  cursor: pointer;
  font-weight: 700;
  color: var(--blue-accent);
}

.resendCount {
  font-weight: 700;
  color: var(--grey-text);
}
