.container {
  h3 {
    margin-bottom: 4px;
  }
}

.filtersContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.selectFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  :global {
    .selectButton {
      &:nth-child(2) {
        width: 208px;
      }
      &:nth-child(3) {
        width: 260px;
        margin-right: 8px;
        .selectButtonWrapper {
          color: var(--blue-accent);
        }
        .selectDropdown {
          width: 260px;
        }
      }
    }
  }
  .sortWrapper {
    border-left: 1px solid var(--grey-border-2);
    padding-left: 16px;
    :global {
      .selectButton {
        width: 99px;
        .selectButtonWrapper {
          color: var(--blue-accent);
        }
      }
    }
  }
}

.buttonWrapper {
  display: flex;
}

.userCell {
  display: flex;
  align-items: center;
  gap: 8px;
}

.userRight {
  div:first-child {
    margin-bottom: 4px;
  }
}

.avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-position: center;
  background-size: cover;
  background-color: var(--grey-border-4);

  &.small {
    width: 24px;
    height: 24px;
  }
}

.greyText {
  color: var(--grey-text);
}

.redText {
  color: var(--dark-red);
}
