.container {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    width: 36px;
    height: 52px;
    border-radius: 8px;
    border: 1px solid var(--grey-border);
    font-size: 18px;
    font-weight: 600;
    text-align: center;

    &:focus {
      outline: none;
      border: 1px solid var(--black-text);
    }
  }

  .inputWrapper {
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  .title {
    font-weight: 600;
    text-align: center;
    margin-top: 0;
    margin-bottom: 24px;
  }

  .errorWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    color: var(--dark-red);

    svg {
      flex-shrink: 0;
      margin-right: 4.5px;
    }
  }
}
