.formContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.textWrapper {
  text-align: center;

  h3 {
    margin-bottom: 20px;
  }
}

.loginControl {
  display: flex;
  flex-direction: column;
  gap: 10px;

  button {
    width: 100%;
  }
}
