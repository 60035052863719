.container {
  padding: 9.5px 39px;
  border-radius: 8px;
  margin: 0;
  cursor: pointer;
  color: var(--white);
  font-weight: bold;
  transition: all ease-in-out 0.2s;

  &.disabled {
    pointer-events: none;
  }
}
.primary {
  border: 1px solid var(--blue-accent);
  background-color: var(--blue-accent);
  &:hover {
    border: 1px solid var(--blue-accent-hover);
    background-color: var(--blue-accent-hover);
  }
  &.disabled {
    border: 1px solid var(--grey-border-2);
    background-color: var(--grey-border-2);
  }
}
.danger {
  border: 1px solid var(--dark-red);
  background-color: var(--dark-red);
  &:hover {
    border: 1px solid var(--dark-red-hover);
    background-color: var(--dark-red-hover);
  }
  &.disabled {
    border: 1px solid var(--grey-border-2);
    background-color: var(--grey-border-2);
  }
}
.outline {
  border: 1px solid var(--blue-accent);
  background-color: var(--transparent);
  color: var(--blue-accent);
  &:hover {
    color: var(--white);
    background-color: var(--blue-accent);
  }
  &.disabled {
    opacity: 0.4;
  }
}
.transparent {
  border: 1px solid var(--transparent);
  background-color: var(--transparent);
  color: var(--black-text);
  &:hover {
    border: 1px solid var(--grey-border-2);
    background-color: var(--grey-border-2);
  }
  &.disabled {
    color: var(--grey-border-2);
  }
}
