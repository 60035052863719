.header,
.headerRow {
  display: flex;
  align-items: center;
}

.header {
  gap: 16px;
  padding: 20px 0;
}

.avatar {
  flex-shrink: 0;
  width: 72px;
  height: 72px;
  border-radius: 40px;
  background-size: cover;
  background-position: center;
  background-color: var(--grey-border-4);
}

.headerRow {
  &:first-child {
    margin-bottom: 8px;
  }
}

.infoText {
  padding: 0 8px;
  color: var(--grey-text);
  &:first-child {
    padding-left: 0;
    border-right: 1px solid var(--grey-border-3);
  }
}
