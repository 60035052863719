@import '../../assets/style/effects.scss';

.container {
  margin-top: 20px;
}

.filtersContainer {
  margin-bottom: 20px;
}

.searchWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.searchInput {
  position: relative;
  input {
    width: 520px;
    padding: 10px 104px 10px 42px;
    border-radius: 8px;
    border: 1px solid var(--grey-border-4);
    &:focus {
      outline: none;
      border: 1px solid var(--black-text);
    }
    &::placeholder {
      color: var(--grey-text);
    }
  }
  .searchIcon {
    position: absolute;
    top: 8px;
    left: 8px;
  }
}

.numberRecord {
  font-weight: 600;
  margin-left: 16px;
  color: var(--grey-text);
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 20px;
}

.selectFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  :global {
    .selectButton {
      //schedule
      &:nth-child(1) {
        width: 232px;
      }
      //status
      &:nth-child(2) {
        width: 156px;
      }
      //role
      &:nth-child(3) {
        width: 216px;
        .selectDropdown {
          width: 216px;
        }
      }
      // district
      &:nth-child(5) {
        width: 120px;
        .selectDropdown {
          width: 120px;
        }
      }
    }
  }
}

.buttonWrapper {
  display: flex;
  gap: 16px;
}

.line1 {
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.line2 {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.greyText {
  color: var(--grey-text);
}

.buttonCell {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.moreToggle {
  cursor: pointer;
  position: relative;
}

.moreDropdown {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: calc(100% + 36px);
  right: -24px;
  border-radius: 10px;
  background-color: var(--white);
  width: max-content;
  overflow: hidden;
  @include box-shadow-1;

  .moreButton:first-child {
    padding: 16px 16px 7.5px;
  }
  .moreButton:last-child {
    padding: 7.5px 16px 16px;
  }
}

.moreButton {
  display: flex;
  gap: 8px;
  align-items: center;

  &:hover {
    background-color: var(--grey-border-3);
  }
}
