@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');
@import './assets/style/colors.scss';
@import './assets/style/common.scss';

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  background-color: var(--body-bg);
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: var(--black-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button,
textarea {
  font-family: 'Nunito', sans-serif;
}

input,
textarea {
  font-size: 14px;
  line-height: 19px;
  color: var(--black-text);
}

.main-content {
  margin-left: 116px;
  padding: 48px;
}

.main-header {
  background: var(--white);
  padding: 48px 48px 0;
  margin: -48px -48px 0;
}

h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 49px;
  margin: 0;
  padding-bottom: 20px;
}

h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  margin: 0;
}

h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin: 0;
}

body {
  .simplebar-track.simplebar-vertical {
    width: 8px;
  }
  .simplebar-scrollbar:before {
    left: 0px;
    right: 4px;
    background: var(--grey-text);
  }
}

.modal-show {
  overflow: hidden;
  padding-right: 17px;
}

.greyText {
  color: var(--grey-text);
}
