.loading-show {
  .spinner-box {
    display: flex;
  }
}

.spinner-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: var(--transparent-black);
  display: none;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #4285f4;
  border-right: 16px solid #0f9d58;
  border-bottom: 16px solid #f48400;
  border-left: 16px solid #db4437;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
