.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logoWrapper {
  background: url('../../assets/img/sparkletots_logo.png');
  width: 129px;
  height: 86px;
  margin-top: 48px;
}
.formWrapper {
  width: 414px;
  background: var(--white);
  border-radius: 4px;
  border-top: 4px solid var(--blue-accent);
  margin-top: 20px;
  padding: 48px 29px 32px;
  position: relative;
}
