.headerWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
.header {
  padding: 20px 0;
}
.container {
  padding-top: 24px;
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 24px;
  }

  hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--grey-border-2);
    margin-bottom: 36px;
  }
}

.inputItem {
  width: 500px;
  margin-bottom: 36px;

  textarea {
    height: 96px;
  }
}

.radioItem {
  display: inline-block;
  margin-right: 40px;
}

.inputLabel {
  display: inline-block;
  font-weight: 600;
  margin-bottom: 5px;
}

.optionalLabel {
  color: var(--grey-text);
  font-weight: 400;
  padding-left: 8px;
}

.timeWrapper {
  display: flex;
  align-items: flex-start;

  & > *:nth-child(1) {
    width: 164px;
    margin-right: 22px;
  }
  & > *:nth-child(2) {
    width: 164px;
    margin-right: 42px;
  }
  & > *:nth-child(3) {
    margin-top: 35px;
  }
}

.recipientsWrapper {
  display: flex;
  flex-direction: column;
  gap: 36px;

  :global {
    .selectButton,
    .selectDropdown {
      width: 228px;
    }
  }
}

.errorWrapper {
  display: flex;
  font-size: 12px;
  line-height: 16px;
  color: var(--dark-red);
  margin-bottom: 12px;

  svg {
    flex-shrink: 0;
    margin-right: 4.5px;
  }
}

.buttonWrapper {
  display: flex;
  gap: 36px;
}

.thumbnailWrapper {
  position: relative;
  display: inline-block;
  height: 130px;
  max-width: 372px;
  padding: 4px;
  border-radius: 8px;
  background-color: var(--white);

  img {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
    border-radius: 8px;
  }

  .removeIcon {
    position: absolute;
    bottom: 2px;
    right: 2px;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grey-border-5);
    cursor: pointer;
    transition: all ease 0.2s;

    &:hover {
      background-color: var(--grey-border-2);
    }
  }
}
