.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: var(--transparent-black);
  justify-content: center;
  align-items: center;
  display: none;
  &.show {
    display: flex;
    padding-right: 17px;
  }
}

.wrapper {
  background-color: var(--white);
  border-radius: 8px;
  padding: 64px 32px 32px;
  position: relative;
}

.closeIcon {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.header {
  margin-bottom: 16px;
}

.body {
  margin-bottom: 42px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 36px;
}
