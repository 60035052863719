.container {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  input[type='checkbox'] {
    appearance: none;
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0;
    &:checked + .checkbox {
      border: 1px solid var(--blue-accent);
      background: var(--blue-accent);
      .checkIcon {
        transform: rotate(0) scale(1);
      }
    }
  }
}
.checkbox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--blue-accent);
  border-radius: 1px;
  width: 12px;
  height: 12px;
  margin: 2px;
  transition: all ease 0.2s;
}
.checkIcon {
  transition: all ease 0.2s;
  transform: rotate(90deg) scale(0);
}
.labelText {
  font-weight: bold;
  margin-left: 8px;
}
