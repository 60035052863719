.container {
  padding-top: 24px;
  padding-bottom: 140px;
}

.fieldItem {
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    width: 372px;
    margin-bottom: 36px;
  }
  &:nth-child(4) {
    margin-bottom: 36px;
    :global {
      .selectButton {
        width: 270px;
      }
    }
  }
  &:nth-child(5) {
    width: 600px;
    .centresWrapper {
      margin-bottom: 28px;
    }
  }
}

.labelText {
  font-weight: 600;
  margin-bottom: 6px;
}

.buttonWrapper {
  margin-top: 48px;
  display: flex;
  gap: 36px;
}

.bodyModal {
  height: 508px;
  background-color: var(--body-bg);
  border-radius: 10px;
  padding: 20px;
}

.searchInput {
  position: relative;
  input {
    width: 368px;
    padding: 10px 10px 10px 42px;
    border-radius: 8px;
    border: 1px solid var(--grey-border-4);
    &:focus {
      outline: none;
      border: 1px solid var(--black-text);
    }
    &::placeholder {
      color: var(--grey-text);
    }
  }
  .searchIcon {
    position: absolute;
    top: 8px;
    left: 8px;
  }
}

.filters {
  margin-bottom: 12px;
  :global {
    .selectButton {
      width: 168px;
    }
  }
}

.recordText {
  margin-bottom: 20px;
}

.filters,
.recordText {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.greyText {
  color: var(--grey-text);
}

.blueText {
  color: var(--blue-accent);
  font-weight: 600;
}

.disabledText {
  opacity: 0.4;
}

.errorWrapper {
  display: flex;
  font-size: 12px;
  line-height: 16px;
  color: var(--dark-red);
  margin-top: 9px;
  svg {
    flex-shrink: 0;
    margin-right: 4.5px;
  }
}

.buttonCell {
  cursor: pointer;
}
