.container {
  padding-bottom: 12px;
  display: inline-flex;
  align-items: center;
}
.itemWrapper {
  display: inline-flex;
  align-items: center;
}
.linkItem {
  font-weight: 600;
  cursor: pointer;

  &.active {
    color: var(--blue-accent);
    cursor: default;
  }
}
.arrowIcon {
  margin: 0 7px;
  flex: 1;
}
