.DayPicker-Weekday,
.dayItem {
  display: inline-flex;
  padding: 0;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  color: var(--black-text);
}
.DayPicker-Day--outside .dayItem,
.DayPicker-Day--disabled .dayItem {
  color: var(--grey-text);
  cursor: default;
  opacity: 0.5;
}

.InputFromTo {
  color: var(--blue-accent);
  input {
    width: 80px;
    border: none;
    padding: 0;
    outline: none;
    color: var(--blue-accent);
  }
  &.hideInput {
    opacity: 0;
    input {
      width: 0;
    }
  }
  .DayPicker-Day--selected {
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: unset;
      .dayItem {
        background-color: var(--blue-accent);
        color: var(--white);
      }
    }
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
      background-color: unset;
    }
    &:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: var(--light-blue);
      .dayItem {
        background-color: var(--light-blue);
        color: var(--black-text);
      }
    }
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--end {
      background-color: var(--light-blue);
      &.DayPicker-Day--start {
        background-color: unset;
      }
    }
  }
}

.InputMultiple {
  .DayPicker-Day--selected {
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: unset;
      .dayItem {
        background-color: var(--blue-accent);
        color: var(--white);
      }
    }
    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)
      + .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: var(--light-blue);
    }
  }
}

.DayPicker {
  &:not(.DayPicker--interactionDisabled) {
    .DayPicker-Day {
      &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background-color: unset;
      }
      &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
        .dayItem {
          &:hover {
            background-color: var(--light-blue);
          }
        }
      }
    }
  }
}
.DayPicker-Day {
  border-radius: 0;
}
.DayPickerInput-OverlayWrapper {
  position: initial;
}
.DayPickerInput-Overlay {
  top: calc(100% + 8px);
  border-radius: 10px;
  overflow: hidden;
  cursor: default;
}
.DayPicker-wrapper {
  padding-bottom: 0;
}
.DayPicker-Month {
  display: block;
  margin: 15px;
}
.deselectWrapper {
  text-align: right;
  color: var(--grey-text);
  font-weight: 600;
  background-color: var(--grey-border-5);
  padding: 8px 16px;
  span {
    cursor: pointer;
  }
  .active {
    color: var(--blue-accent);
  }
}
.monthYearLabel {
  margin-top: 10px;
  text-align: center;
  font-weight: 700;
  color: var(--black-text);
}
.DayPicker-Weekdays {
  display: block;
  margin-top: 20px;
  margin-bottom: 12px;
}
.DayPicker-WeekdaysRow {
  display: flex;
  justify-content: space-between;
  gap: 29.5px;
}
.dayItem {
  font-size: 12px;
  line-height: 13px;
  border-radius: 2px;
  transition: all ease 0.2s;
}
.DayPicker-Body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.DayPicker-Week {
  display: block;
}
.DayPicker-Day {
  display: inline-block;
  padding: 0 0 0 29.5px;
  transition: all ease 0.2s;
  &:first-child {
    padding-left: 0;
  }
}
.DayPicker-Day--today {
  .dayItem {
    color: var(--black-text);
    font-weight: normal;
    background-color: var(--light-blue);
    border-radius: 12px;
    &:hover {
      border-radius: 2px;
    }
  }
  &.DayPicker-Day--selected {
    .dayItem {
      border-radius: 2px;
    }
  }
}
.navBar {
  width: 100%;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 20px;
  button {
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    border: none;
    background-color: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-child {
      transform: rotateY(180deg);
    }
  }
}

.PcfDatePicker {
  display: inline-flex;
  flex-direction: column;
  width: 232px;
  &.border {
    width: 100%;
    .buttonWrapper {
      border: 1px solid var(--grey-border);
      border-radius: 8px;
      padding: 10px 38px 10px 12px;
      display: block;
      &.error {
        border: 1px solid var(--dark-red);
      }
    }
    .fixedLabel {
      position: absolute;
      margin-right: 0;
      bottom: 10px;
      right: 9px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .labelWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-weight: 600;
  }
  .selectedNumber {
    font-weight: 400;
    color: var(--grey-text);
  }
  .errorWrapper {
    display: flex;
    font-size: 12px;
    line-height: 16px;
    color: var(--dark-red);
    margin-top: 9px;
    svg {
      flex-shrink: 0;
      margin-right: 4.5px;
    }
  }
  .buttonWrapper {
    position: relative;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    padding: 10.5px 8px;
    cursor: pointer;
    background-color: var(--white);
    display: flex;
    align-items: center;
  }
  .fixedLabel {
    margin-right: 8px;
    display: inline-flex;
  }
  .defaultLabel {
    color: var(--grey-text);
    position: absolute;
  }
  .selectedValue {
    flex: 1;
  }
  .arrowWrapper {
    transition: all ease 0.2s;
    &.arrowUp {
      transform: rotate(180deg);
    }
  }
}

.InputMultiple {
  input {
    width: 100%;
    border: none;
    padding: 0;
    outline: none;
  }
  .DayPickerInput {
    display: block;
  }
}

.PcfDatePicker.disabled {
  .buttonWrapper,
  input {
    background: var(--grey-border-3);
    color: var(--grey-text);
  }
}
