.container {
  h3 {
    margin-bottom: 20px;
  }
}
.filtersContainer {
  margin-bottom: 20px;
}
.selectFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  :global {
    .selectButton {
      &:nth-child(1) {
        width: 116px;
      }
    }
  }
  .sortWrapper {
    border-left: 1px solid var(--grey-border-2);
    padding-left: 16px;
    :global {
      .selectButton {
        width: 200px;
        .selectButtonWrapper {
          color: var(--blue-accent);
        }
        .selectDropdown {
          white-space: nowrap;
        }
      }
    }
  }
}

.ratingNoText {
  color: var(--grey-text);
  font-weight: normal;
  margin-left: 12px;
  padding-left: 12px;
  border-left: 1px solid var(--grey-border-2);
}

.userCell {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}

.userRight {
  div:first-child {
    margin-bottom: 4px;
  }
  div:last-child {
    span {
      &:first-child {
        padding-right: 8px;
        border-right: 1px solid var(--grey-border-3);
      }
      &:last-child {
        padding-left: 8px;
      }
    }
  }
}

.avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-position: center;
  background-size: cover;
  background-color: var(--grey-border-4);
}

.greyText {
  color: var(--grey-text);
}

.dateCell {
  white-space: nowrap;
}
