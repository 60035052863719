.selectDropdown .optionGroupItem {
  background-color: var(--grey-border-3-06);
  border-top: 1px solid var(--grey-border-2);
  &::after {
    content: none;
  }
  & + div {
    &::after {
      content: none;
    }
  }
}
