.container .wrapper {
  position: relative;
}

.fileInput {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  inset: 0px;
  width: 372px;
  z-index: 1;
}

.dropArea {
  width: 372px;
  height: 130px;
  border: 1px dashed var(--grey-border);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  background-color: var(--white);

  div:nth-child(1) {
    padding-top: 40px;
  }
  div:nth-child(2) {
    color: var(--blue-accent);
    padding-bottom: 12px;
  }
  div:nth-child(3) {
    color: var(--grey-text);
    font-size: 12px;
    font-weight: 400;
  }
}

.disabled .dropArea {
  background-color: var(--grey-border-3);
}

.fileItem {
  width: 372px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
}

.fileWrapper {
  width: 330px;
  height: 52px;
  background-color: var(--white);
  border-radius: 4px;
  padding-left: 23px;
  padding-right: 6px;
  display: flex;
  align-items: center;
}

.fileInfo {
  padding-left: 12px;
  width: 258px;

  div:nth-child(1) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
  }
  div:nth-child(2) {
    font-size: 10px;
    font-weight: 600;
    color: var(--grey-text);
  }
}

.removeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  cursor: pointer;
  svg {
    width: 12px;
    height: 12px;
  }
}

.completeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue-accent);
  width: 21px;
  height: 21px;
  border-radius: 12px;
  svg {
    width: 14px;
    height: 14px;
  }
}

.previewImage {
  width: 372px;
  height: 372px;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}
