.container {
  display: inline-block;
  .label {
    margin-bottom: 5px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }
  .greyText {
    font-weight: 400;
    color: var(--grey-text);
  }
  .pickersWrapper {
    display: flex;
    gap: 14px;
  }
  .picker {
    :global {
      .selectButton {
        width: 164px;
      }
    }
  }
  .toText {
    margin-top: 12px;
  }
  .icon {
    position: absolute;
    top: 10px;
    right: 9px;
  }
  input {
    padding: 0;
    border: none;
    outline: none;
    width: 100%;
  }
}
