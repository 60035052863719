.container {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.roleItem {
  width: 720px;
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
}

.buttonWrapper {
  margin-top: 4px;
  display: flex;
  gap: 36px;
}
