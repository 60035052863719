.header {
  display: flex;
  column-gap: 214px;
  align-items: flex-start;
  *:nth-child(2) {
    flex-shrink: 0;
  }
}

.title {
  padding-bottom: 20px;
  h1 {
    padding-bottom: 4px;
  }
}
