.filtersContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}
.selectFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  :global {
    .selectButton {
      &:nth-child(1) {
        width: 216px;
      }
    }
  }
}

.userCell {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}

.userRight {
  div:first-child {
    margin-bottom: 4px;
  }
}

.avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-position: center;
  background-size: cover;
  background-color: var(--grey-border-4);
}

.greyText {
  color: var(--grey-text);
}

.dateCell {
  white-space: nowrap;
}
