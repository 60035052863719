.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.inputItem {
  width: 45%;
  & .selectButton {
    width: 100%;
  }
}

.button {
  margin-top: 20px;
}
