:root {
  --white: #fff;
  --body-bg: #f7f8fd;
  --blue-accent: #00529c;
  --blue-accent-hover: #007dbd;
  --black-text: #333;
  --grey-text: #828282;
  --dark-red: #be3129;
  --dark-red-hover: #e25243;
  --orange-red: #d14205;
  --grey-border: #c2c9d1;
  --grey-border-2: #d6d6d6;
  --grey-border-3: #f4f4f4;
  --grey-border-4: #dfdfdf;
  --grey-border-5: #f0f0f0;
  --grey-border-3-06: #f4f4f499;
  --light-green: #8dc63f;
  --light-blue: #e6e9f8;
  --bright-blue: #1ea0d0;
  --cyan-blue: #3486a5;
  --purple: #662d91;
  --yellow: #fbb040;
  --transparent: rgba(255, 255, 255, 0);
  --transparent-black: rgba(0, 0, 0, 0.3);
}
