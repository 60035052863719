.container {
  border-radius: 10px;
  padding: 2px 8px;
  color: var(--white);
  font-weight: 600;
  white-space: nowrap;
  display: inline-flex;

  &.cyanBlue {
    background-color: var(--cyan-blue);
  }
  &.lightGreen {
    background-color: var(--light-green);
  }
  &.brightBlue {
    background-color: var(--bright-blue);
  }
  &.jobPast {
    background-color: var(--grey-border-2);
    color: var(--black-text);
  }
  &.orangeRed {
    background-color: var(--orange-red);
  }
  &.purple {
    background-color: var(--purple);
  }
  &.yellow {
    background-color: var(--yellow);
  }

  &.skillIconText {
    img {
      height: 14px;
    }
    display: inline-flex;
    gap: 4px;
    align-items: center;
  }
  &.skillIcon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
}
