.container {
  h3 {
    margin-bottom: 12px;
  }

  table {
    margin-bottom: 20px;
  }
}

.userCell {
  display: flex;
  align-items: center;
  gap: 8px;
}

.userRight {
  div:first-child {
    margin-bottom: 4px;
  }
}

.avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-position: center;
  background-size: cover;
  background-color: var(--grey-border-4);
}

.greyText {
  color: var(--grey-text);
}

.buttonsCell {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  button {
    padding: 8.5px 20px;
  }
  button:first-child {
    margin-right: 12px;
  }
  button:nth-child(2) {
    margin-right: 20px;
  }
}
