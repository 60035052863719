.container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 248px;
  row-gap: 50px;
}
.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.inputItem {
  &:nth-child(1) {
    :global {
      .selectButton {
        width: 214px;
      }
    }
  }
  &:nth-child(2),
  &:nth-child(5) {
    width: 400px;
  }
  &:nth-child(3),
  &:nth-child(4) {
    width: 153px;
  }
  &:nth-child(5) {
    textarea {
      height: 120px;
    }
  }
}

.qualificationsWrapper {
  flex: 1;
}

.label {
  font-weight: 600;
  margin-bottom: 5px;
}

.greyText {
  color: var(--grey-text);
}
