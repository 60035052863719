@import '../../assets/style/effects.scss';

.container {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 116px;
  height: 100%;
  background-color: var(--blue-accent);
  z-index: 2;

  :global {
    .simplebar-content {
      height: 100%;
    }
  }
}
.content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.logo {
  background: url('../../assets/img/sidebar_logo.png');
  width: 100%;
  height: 128px;
  flex-shrink: 0;
}
.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 9px;
}
.navList {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.navItem {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 4px;
  transition: all ease 0.2s;
  &:hover {
    background-color: var(--dark-red-hover);
  }
  &.active {
    background-color: var(--dark-red);
  }
}
.userButton {
  cursor: pointer;
  margin-top: 32px;
  margin-bottom: 24px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: var(--white);
  background-position: center;
  background-size: cover;
}
.accountPanel {
  @include box-shadow-1;
  z-index: 3;
  position: fixed;
  left: 140px;
  bottom: 24px;
  overflow: hidden;
  opacity: 1;
  border-radius: 10px;
  background-color: var(--white);
  animation-name: hide;
  animation-duration: 0.2s;
  animation-timing-function: ease;
}
.showHideAnimation {
  animation-name: show;
}
@keyframes show {
  from {
    left: 38px;
    opacity: 0;
  }
  to {
    left: 140px;
    opacity: 1;
  }
}
@keyframes hide {
  from {
    left: 140px;
    opacity: 1;
  }
  to {
    left: 38px;
    opacity: 0;
  }
}
.actionItem {
  cursor: pointer;
  padding: 12px 16px;
  transition: all ease 0.2s;
  &:hover {
    background-color: var(--grey-border-3);
  }
}
