@import '../../../assets/style/effects.scss';

.container {
  margin-top: 20px;
}
.filtersContainer {
  margin-bottom: 20px;
}
.searchWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.searchInput {
  position: relative;
  input {
    width: 520px;
    padding: 10px 104px 10px 42px;
    border-radius: 8px;
    border: 1px solid var(--grey-border-4);
    &:focus {
      outline: none;
      border: 1px solid var(--black-text);
    }
    &::placeholder {
      color: var(--grey-text);
    }
  }
  .searchIcon {
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
.numberRecord {
  font-weight: 600;
  margin-left: 16px;
  color: var(--grey-text);
}
.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.selectFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  :global {
    .selectButton {
      &:nth-child(1) {
        width: 270px;
      }
      &:nth-child(2) {
        width: 372px;
      }
    }
  }
  .sortWrapper {
    border-left: 1px solid var(--grey-border-2);
    padding-left: 8px;
    :global {
      .selectButton {
        width: 204px;
        .selectDropdown {
          width: 204px;
        }
        .selectButtonWrapper {
          color: var(--blue-accent);
        }
      }
    }
  }
}
.buttonWrapper {
  display: flex;
  gap: 16px;
}
.jobCell {
  display: flex;
  align-items: center;
  gap: 8px;
}
.jobCellInfo {
  color: var(--grey-text);
  margin-top: 4px;
}

.userCell {
  display: flex;
  align-items: center;
  gap: 8px;
}

.userRight {
  div:first-child {
    margin-bottom: 4px;
  }
}

.avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-position: center;
  background-size: cover;
  background-color: var(--grey-border-4);
}

.greyText {
  color: var(--grey-text);
}

.buttonCell {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.moreToggle {
  cursor: pointer;
  position: relative;
}

.moreDropdown {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: calc(100% + 36px);
  right: -24px;
  border-radius: 10px;
  background-color: var(--white);
  width: max-content;
  @include box-shadow-1;

  .moreButton:first-child {
    padding: 16px 16px 7.5px;
  }
  .moreButton:last-child {
    padding: 7.5px 16px 16px;
  }
}

.tooltipContent {
  position: absolute;
  z-index: 5;
  top: 100%;
  padding: 10px;
  background: #00000080;
  border-radius: 10px;
  color: white;
}

.moreButton {
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;

  &:hover {
    background-color: var(--grey-border-3);
  }
}
.disabled {
  opacity: 0.5;
  cursor: auto;
}

.txt {
  display: flex;
  align-items: center;
  gap: 8px;
}
.breakSpace {
  white-space: break-spaces;
}
