@import '../../../assets/style/effects.scss';

.container {
  width: 604px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.buttonCell {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.moreToggle {
  cursor: pointer;
  position: relative;
}

.moreDropdown {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: calc(100% + 14px);
  right: -50px;
  border-radius: 10px;
  background-color: var(--white);
  width: max-content;
  overflow: hidden;
  @include box-shadow-1;

  .moreButton:first-child {
    padding: 16px 16px 7.5px;
  }
  .moreButton:last-child {
    padding: 7.5px 16px 16px;
  }
}

.moreButton {
  display: flex;
  gap: 8px;
  align-items: center;

  &:hover {
    background-color: var(--grey-border-3);
  }
}

.modalBody {
  display: flex;
  gap: 30px;
  :global {
    .PcfDatePicker {
      width: 164px;
    }
  }
}
