.container {
  .tabList {
    padding: 0 48px;
    margin: 0 -48px;
    background: var(--white);
    list-style-type: none;
    display: flex;
    gap: 40px;
    border-bottom: 2px solid var(--grey-border-3);
  }
  .navItem {
    cursor: pointer;
    color: var(--grey-text);
    transition: all 0.2s;

    &:hover {
      color: var(--blue-accent-hover);
    }

    &::after {
      content: '';
      margin-top: 14px;
      display: block;
      width: 0;
      height: 2px;
      background: var(--blue-accent);
      transition: width 0.2s;
    }
    &.active {
      color: var(--blue-accent);
      &::after {
        width: 100%;
      }
    }
  }

  .tabContent {
    padding-top: 20px;
  }
  .tabPane {
    display: none;
    &.show {
      display: block;
    }
  }
}
