.container {
  h3 {
    margin-bottom: 20px;
  }
}
.filtersContainer {
  margin-bottom: 20px;
}
.searchWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.searchInput {
  position: relative;
  input {
    width: 520px;
    padding: 10px 104px 10px 42px;
    border-radius: 8px;
    border: 1px solid var(--grey-border-4);
    &:focus {
      outline: none;
      border: 1px solid var(--black-text);
    }
    &::placeholder {
      color: var(--grey-text);
    }
  }
  .searchIcon {
    position: absolute;
    top: 8px;
    left: 8px;
  }
  .searchFilterWrapper {
    position: absolute;
    top: 7px;
    right: 12px;
    :global {
      .selectButton {
        width: 84px;
      }
      .selectButtonWrapper {
        background-color: var(--grey-border-3);
        padding: 0 8px;
        height: 27px;
      }
    }
  }
}
.numberRecord {
  font-weight: 600;
  margin-left: 16px;
  color: var(--grey-text);
}
.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.selectFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  :global {
    .selectButton {
      // Role
      &:nth-child(1) {
        width: 216px;
      }
      // Status
      &:nth-child(2) {
        width: 147px;
      }
      // District
      &:nth-child(4) {
        width: 158px;
      }
    }
    .jobGroupItem {
      font-weight: 400;
      color: var(--grey-text);
      padding-bottom: 0;
      &:first-child,
      + div {
        padding-top: 8px;
      }
    }
  }
}
.buttonWrapper {
  display: flex;
  gap: 16px;
}
.jobCell {
  display: flex;
  align-items: center;
  gap: 8px;
}
.jobCellInfo {
  color: var(--grey-text);
  margin-top: 4px;
}
.buttonCell {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}
