.container {
  margin-top: 20px;
}
.filtersContainer {
  margin-bottom: 20px;
}
.searchWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.searchInput {
  position: relative;
  input {
    width: 520px;
    padding: 10px 104px 10px 42px;
    border-radius: 8px;
    border: 1px solid var(--grey-border-4);
    &:focus {
      outline: none;
      border: 1px solid var(--black-text);
    }
    &::placeholder {
      color: var(--grey-text);
    }
  }
  .searchIcon {
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
.numberRecord {
  font-weight: 600;
  margin-left: 16px;
  color: var(--grey-text);
}
.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.selectFilters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  :global {
    .selectButton {
      &:nth-child(1) {
        width: 216px;
      }
      &:nth-child(2) {
        width: 204px;
        .selectButtonWrapper {
          color: var(--blue-accent);
        }
        .selectDropdown {
          width: 100%;
        }
      }
    }
  }
  .sortWrapper {
    border-left: 1px solid var(--grey-border-2);
    padding-left: 16px;
    :global {
      .selectButton {
        width: 204px;
        .selectButtonWrapper {
          color: var(--blue-accent);
        }
      }
    }
  }
}
.noExpiryCheckbox {
  cursor: pointer;
  padding: 12px 16px;
  transition: all ease 0.2s;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
  position: relative;

  &:hover {
    background-color: var(--grey-border-3);
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: calc(100% - 32px);
    top: 0px;
    border-bottom: 1px solid var(--grey-border-3);
  }
}
.buttonWrapper {
  display: flex;
}
.jobCell {
  display: flex;
  align-items: center;
  gap: 8px;
}
.jobCellInfo {
  color: var(--grey-text);
  margin-top: 4px;
}

.userCell {
  display: flex;
  align-items: center;
  gap: 8px;
}

.userRight {
  div:first-child {
    margin-bottom: 4px;
  }
}

.avatar {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-position: center;
  background-size: cover;
  background-color: var(--grey-border-4);
}

.greyText {
  color: var(--grey-text);
}

.redText {
  color: var(--dark-red);
}

.buttonCell {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}
