@import '../../assets/style/effects.scss';

.header {
  padding: 20px 0;
  gap: 15px;
}
.container {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.avatar {
  flex-shrink: 0;
  width: 72px;
  height: 72px;
  border-radius: 40px;
  background-size: cover;
  background-position: center;
  position: relative;
  background-color: var(--grey-border-4);
}

.changeAvatar {
  position: absolute;
  background-color: var(--blue-accent);
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
  cursor: pointer;

  &:hover {
    background-color: var(--blue-accent-hover);
  }
}

.header,
.headerRow,
.moreButton,
.moreToggle {
  display: flex;
  align-items: center;
}

.headerRow {
  &:first-child {
    margin-bottom: 8px;
  }
}

.moreToggle {
  margin-left: 16px;
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background-color: var(--light-blue);
  position: relative;
  justify-content: center;
}

.moreButton {
  cursor: pointer;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  border-radius: 10px;
  padding: 16px;
  background-color: var(--white);
  width: max-content;
  gap: 8px;
  @include box-shadow-1;
}

.infoText {
  padding: 0 9px;
  color: var(--grey-text);
  &:first-of-type {
    padding-left: 0;
    border-right: 1px solid var(--grey-border-3);
  }
}
